import { TextField, makeStyles } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { DataContext } from 'context/DataContext';
import config from 'config';
import { Paper } from '@mui/material';
import './App.css';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { purple, red } from '@mui/material/colors';
import { ToastContainer, toast } from 'react-toastify';

import logoImg from './assets/images/logo.png'


function LoginAdmin() {
    const notify = (text) => toast.success(text);
   
    const accent = purple['A400'];
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { loginname, setLoginname, loginstatus, setLoginstatus, login_id, setLogin_id } = useContext(DataContext);
   
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handlelogin = (e) => {
        e.preventDefault();
        const formdata = { email, password };
        console.log(formdata);
        fetch(`${config.url}/admin/adminlogin`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formdata)
        })
            .then((res) => {

                return res.json();
            })
           
            .then((data) => {
                if (data.userrecord != null && data.userrecord.role == 'admin') {
                    //     setJwt(headers.get('authorization'));
                    localStorage.setItem('login_id', data.userrecord._id);
                    setLogin_id(localStorage.getItem('login_id'));
                    localStorage.setItem('jwt', data.token);
                    localStorage.setItem('user_type', 'admin');
                    localStorage.setItem('password', password);
                    localStorage.setItem('loginname', data.userrecord.name);
                    setLoginname(localStorage.getItem('loginname'));
                    localStorage.setItem('loginstatus', '1');
                    setLoginstatus(localStorage.getItem('loginstatus'));
                    setTimeout(() => {
                        notify('Log in Admin Succesfully !');
                    }, 1)
                    navigate('/admin/dashboard/default', { replace: true });
                } else {
                    setMessage(data.message);
                    toast.error(data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            });
        // .catch((error) => {
        //     // alert('service error');
        //     console.log(error);
        // });
    };

    return (
        <div className='background_main_box'>
            <div className="App">
                <div className='logo_main'>
                   
                    <img src={logoImg} alt='logo' />
                </div>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Chip icon={<AccountCircleIcon className="btn-color" />} label="Admin Login" className="btn-color" />
                    <br /> <br />
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }} margin="normal">
                        <TextField
                            fullWidth
                            required
                            id="email"
                            label="Email Address"
                            variant="standard"
                            name="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />

                    </Box>
                    <br /> <br />
                    <FormControl sx={{ width: '100%' }} variant="standard" margin="normal">
                        <InputLabel htmlFor="standard-adornment-password" required>
                            Password
                        </InputLabel>
                        <Input
                            fullWidth
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <br />
                    <br />
                    <br />
                    <diV>
                        <Button fullWidth className="custum_btn btn-color" startIcon={<LoginIcon />} onClick={handlelogin} >
                            Login
                        </Button>

                    </diV>

                </Paper>
                <br />
                <br />
                {/* <h6 className="text-danger"> {}</h6> */}
                {message && <ToastContainer />}
            </div>
        </div>
    );
}

export default LoginAdmin;
