import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { BrowserRouter, Route, Routes, Link, useLocation } from 'react-router-dom';

// routing
import RouteS from 'routes';
import PrivateRoute from 'PrivateRoute';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useState } from 'react';
import LoginAdmin from './LoginAdmin';
import OtherPrivateRoute from 'OtherPrivateRoute';
import 'react-toastify/dist/ReactToastify.css';
//{isDealer ? <DealerRoutes /> : <RouteS />}
// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const location = useLocation();
    const pathname = location.pathname;
    // const isDealer = pathname.includes('dealer');
    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <RouteS />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    );
};

export default App;
