// assets
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import GroupIcon from '@mui/icons-material/Group';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import GradingIcon from '@mui/icons-material/Grading';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
//import according to role
import config from 'config';
//basename={config.basename}

const utilities = {
    id: 'utilities',
    // title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-driver',
            title: 'Driver Management',
            type: 'collapse',
            icon: DriveEtaIcon,
            children: [
                {
                    id: 'util-driver',
                    title: 'View All Drivers',
                    type: 'item',
                    url: `${config.basename}/utils/util-driver`,
                    breadcrumbs: false
                },
                {
                    id: 'util-order_management',
                    title: 'Order Management',
                    type: 'item',
                    url: `${config.basename}/utils/util-order`,
                    breadcrumbs: false
                },

            ]
        },
        {
            id: 'util-user',
            title: 'Users Management',
            type: 'collapse',
            icon: GroupIcon,
            children: [
                {
                    id: 'util-user',
                    title: 'View Users',
                    type: 'item',
                    url: `${config.basename}/utils/util-user`,
                    breadcrumbs: false
                },
                // {
                //     id: 'util-add',
                //     title: 'Add User',
                //     type: 'item',
                //     url: `${config.basename}/utils/util-add`,
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'util-trk',
            title: 'Truck Management',
            type: 'collapse',
            icon: LocalShippingIcon,
            children: [
                {
                    id: 'util-viewCategory',
                    title: 'View Category',
                    type: 'item',
                    url: `${config.basename}/utils/util-viewCategory`,
                    breadcrumbs: false
                },
                {
                    id: 'util-addcategory',
                    title: 'Add Category',
                    type: 'item',
                    url: `${config.basename}/utils/util-addcategory`,
                    breadcrumbs: false
                },
                {
                    id: 'util-viewtruckBrand',
                    title: 'View Truck Brand',
                    type: 'item',
                    url: `${config.basename}/utils/util-viewtruckBrand`,
                    breadcrumbs: false
                },
                {
                    id: 'util-addTruckbrand',
                    title: 'Add Truck Brand',
                    type: 'item',
                    url: `${config.basename}/utils/util-addTruckbrand`,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'util-shipment',
            title: 'Shipment Management',
            type: 'collapse',
            icon: LocalShippingIcon,
            children: [
                {
                    id: 'util-shipment',
                    title: 'Shipment Item List',
                    type: 'item',
                    url: `${config.basename}/utils/util-shipment`,
                    breadcrumbs: false
                },
                {
                    id: 'util-addshipmentItems',
                    title: 'Add shipment Items',
                    type: 'item',
                    url: `${config.basename}/utils/util-addshipmentItems`,
                    breadcrumbs: false
                },
                {
                    id: 'util-viewAshipmentJobs',
                    title: 'View All Shipment Jobs',
                    type: 'item',
                    url: `${config.basename}/utils/util-viewAshipmentJobs`,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'util-manager',
            title: 'Manager Management',
            type: 'collapse',
            icon: GroupIcon,
            children: [
                {
                    id: 'util-manager',
                    title: 'View Manager',
                    type: 'item',
                    url: `${config.basename}/utils/util-manager`,
                    breadcrumbs: false
                },
                {
                    id: 'util-addmanager',
                    title: 'Add Manager',
                    type: 'item',
                    url: `${config.basename}/utils/util-addmanager`,
                    breadcrumbs: false
                }
            ]
        },

       /* {
            id: 'util-globalsetting',
            title: 'Global Settings',
            type: 'collapse',
            icon: SettingsIcon,
            children: [
                {
                    id: 'util-globalsetting',
                    title: 'View Global Settings',
                    type: 'item',
                    url: `${config.basename}/utils/util-globalsetting`,
                    breadcrumbs: false
                },
                // {
                //     id: 'util-editglobalsetting',
                //     title: 'edit globalsetting',
                //     type: 'item',
                //     url: `${config.basename}/utils/util-editglobalsetting`,
                //     breadcrumbs: false
                // }
            ]
        },*/

        // :
        // ( {
        //     id: 'util-user',
        //     title: 'Users',
        //     type: 'collapse',
        //     icon: GroupOutlinedIcon,
        //     children: [
        //         {
        //             id: 'util-user',
        //             title: 'View Users',
        //             type: 'item',
        //             url: `${config.basename}/utils/util-user`,
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'util-add',
        //             title: 'Add User',
        //             type: 'item',
        //             url: `${config.basename}/utils/util-add`,
        //             breadcrumbs: false
        //         }
        //     ]
        // })}
        // {
        //     id: 'util-shadow',
        //     title: 'Shadow',
        //     type: 'item',
        //     url: '/utils/util-shadow',
        //     icon: ContrastOutlinedIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'icons',
        //     title: 'Icons',
        //     type: 'collapse',
        //     icon: ColorLensOutlinedIcon,
        //     children: [
        //         {
        //             id: 'tabler-icons',
        //             title: 'Tabler Icons',
        //             type: 'item',
        //             url: '/icons/tabler-icons',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'material-icons',
        //             title: 'Material Icons',
        //             type: 'item',
        //             url: '/icons/material-icons',
        //             breadcrumbs: false
        //         }
        //     ]
        // }
    ]
};

export default utilities;
