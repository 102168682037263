import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import App from 'App';
import { store } from 'store';

import 'assets/scss/style.scss';
import config from './config';
import ProductContextProvider from 'context/DataContext';
import LoginAdmin from 'LoginAdmin';
import PrivateRoute from 'PrivateRoute';


const container = document.getElementById('root');

const root = createRoot(container);
root.render(
    <ProductContextProvider>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LoginAdmin />} />
                 
                    <Route
                        path="/admin/*"
                        element={
                            <PrivateRoute>
                                <App />
                            </PrivateRoute>
                        }
                    />
                    {/* <Route path="*" element={<Error/>} /> */}
                </Routes>
                <App />
            </BrowserRouter>
        </Provider>
    </ProductContextProvider>
);
