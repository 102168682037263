import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';


import config from 'config';
import { element } from 'prop-types';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const PageNotFound = Loadable(lazy(() => import('views/utilities/PageNotFound')));
const UtilsDriver = Loadable(lazy(() => import('views/utilities/ViewDrivers')));
const UtilsTruckDetails = Loadable(lazy(() => import('views/utilities/ViewTruckDetails')));
const UtilsViewUser = Loadable(lazy(() => import('views/utilities/ViewUser')));
const UtilsviewAllRating = Loadable(lazy(() => import('views/utilities/ViewAllRatings')));
const UtilsViewCategory = Loadable(lazy(() => import('views/utilities/ViewAllcategory')));
const UtilsSubcategory = Loadable(lazy(() => import('views/utilities/ViewSubcategory')));
const Utiladdcategory = Loadable(lazy(() => import('views/utilities/AddCategory')));
const UtilAddSubCategory = Loadable(lazy(() => import('views/utilities/AddSubcategory')));
const UtilsEditSubcategory = Loadable(lazy(() => import('views/utilities/EditSubCategory')));
const UtilsAddTruckbrand = Loadable(lazy(() => import('views/utilities/AddTruckBrand')));
const UtilsViewBrand = Loadable(lazy(() => import('views/utilities/ViewTruckBrand')));
const UtilsEditTruckBrand = Loadable(lazy(() => import('views/utilities/EditTruckBrand')));
const UtilsUpdateDriver = Loadable(lazy(() => import('views/utilities/EditDriver')));
const UtilsEditCategory = Loadable(lazy(() => import('views/utilities/EditCategory')));
const UtilsUpdateUser = Loadable(lazy(() => import('views/utilities/UpdateUser')));
const UtilsEditTruckDetails = Loadable(lazy(() => import('views/utilities/EditTruckDetails')));
const UtilsViewAllJobs = Loadable(lazy(() => import('views/utilities/ViewAllDriverJobs')));
const UtilsShimentItems = Loadable(lazy(() => import('views/utilities/ViewShipmentItem')));
const UtilsAddShipmentItems = Loadable(lazy(() => import('views/utilities/AddShipmentitems')));
const UtilsEditShipmentItems = Loadable(lazy(() => import('views/utilities/EditShipmentItem')));
const UtilsViewUserJobs = Loadable(lazy(() => import('views/utilities/ViewUserJobs')));
const UtilsViewAllJobsShipment = Loadable(lazy(() => import('views/utilities/ViewAllShipmentJob')));
const UtilsViewAllmanager = Loadable(lazy(()=> import('views/utilities/ViewAllmanager')))

const EditForm = Loadable(lazy(() => import('../layout/MainLayout/Header/ProfileSection/EditForm')));


const MainRoutes = {
    path: `${config.basename}`,
    element: <MainLayout />,
    children: [

        {
            path: 'dashboard',
            // element: <Error />,
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },

        {
            path: 'user',
            children: [
                {
                    path: 'edit-profile',
                    element: <EditForm />
                }
            ]
        },

        {
            path: 'utils',
            children: [
                {
                    path: 'util-driver',
                    element: <UtilsDriver />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'truck-details',
                    element: <UtilsTruckDetails />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-user',
                    element: <UtilsViewUser />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'viewAll-rating',
                    element: <UtilsviewAllRating />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-viewCategory',
                    element: <UtilsViewCategory />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'viewAll-subcategory',
                    element: <UtilsSubcategory />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-addcategory',
                    element: <Utiladdcategory />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-addsubcategory',
                    element: <UtilAddSubCategory />
                },

            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'edit-subcategory',
                    element: <UtilsEditSubcategory />
                }
            ]
        },

        {
            path: 'utils',
            children: [
                {
                    path: 'util-addTruckbrand',
                    element: <UtilsAddTruckbrand />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-viewtruckBrand',
                    element: <UtilsViewBrand />
                }
            ]
        },

        {
            path: 'utils',
            children: [
                {
                    path: 'edit-truckbrand',
                    element: <UtilsEditTruckBrand />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-updatedriver',
                    element: <UtilsUpdateDriver />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'edit-category',
                    element: <UtilsEditCategory />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-updateuser',
                    element: <UtilsUpdateUser />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'edit-truckdetails',
                    element: <UtilsEditTruckDetails />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'viewAll-jobs',
                    element: <UtilsViewAllJobs />
                }
            ]
        },

        {
            path: 'utils',
            children: [
                {
                    path: 'util-shipment',
                    element: <UtilsShimentItems />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-addshipmentItems',
                    element: <UtilsAddShipmentItems />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'edit-shipmentItem',
                    element: <UtilsEditShipmentItems />
                }]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'viewUser-jobs',
                    element: <UtilsViewUserJobs />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: "util-viewAshipmentJobs",
                       element: <UtilsViewAllJobsShipment />
                }
            ]
        },
        {
           path: 'utils',
           children: [
               {
                   path: 'util-manager',
                   element: <UtilsViewAllmanager />
               }
           ]
       },
      /*       {
           path: 'utils',
           children: [
               {
                   path: 'util-editglobalsetting',
                   element: <UtilsEditSettingdata />
               }
           ]
       },
      
       {
           path: 'utils',
           children: [
               {
                   path: 'util-ViewUnitcunsuptionhistry',
                   element: <UtilsViewUnitcunsuptionhistry />
               }
           ]
       },
       {
           path: 'utils',
           children: [
               {
                   path: 'util-mtr',
                   element: <UtilsViewUnitmtr />
               }
           ]
       },
*/
        {
            path: '*',
            element: <PageNotFound />
        },

    ]
};

export default MainRoutes;
